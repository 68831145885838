<template>
  <div class="social-sharing__wrap">
    <a
      class="social-sharing__toggler d-block d-md-none"
      @click="isOpen = !isOpen"
      ><i class="icon-sharing-menu"></i
    ></a>

    <div class="social-sharing d-none d-md-block">
      <a
        :href="
          'https://social-plugins.line.me/lineit/share?url=' +
          currentURL
        "
        target="_blank"
      >
        <i class="icon icon-sharing-line"></i>
      </a>
      <a
        :href="
          'https://facebook.com/sharer/sharer.php?u=' +
          currentURL
        "
        target="_blank"
      >
        <i class="icon icon-sharing-fb"></i>
      </a>
      <a @click="copyURL">
        <i class="icon icon-sharing-link"></i>
      </a>
    </div>

    <div class="social-sharing" v-if="isOpen">
      <a
        :href="
          'https://social-plugins.line.me/lineit/share?url=' +
          currentURL
        "
        target="_blank"
      >
        <i class="icon icon-sharing-line"></i>
      </a>
      <a
        :href="
          'https://facebook.com/sharer/sharer.php?u=' +
          currentURL
        "
        target="_blank"
      >
        <i class="icon icon-sharing-fb"></i>
      </a>
      <a @click="copyURL">
        <i class="icon icon-sharing-link"></i>
      </a>
    </div>
  </div>
  <template name="fade" v-if="showFeedback">
    <div class="feedbackCopyURL"><i class="icon icon-check-circle me-2"></i> 複製成功</div>
  </template>
</template>

<script>
import { ref } from "vue";

export default {
  props: [ 'postTitle' ],
  setup(props) {
    let timeId = ref(0);
    let time = ref(1);
    const isOpen = ref(false);
    const currentURL = ref("");
    const showFeedback = ref(false);

    currentURL.value = window.location.href;
console.log("currentURL.value=====>", currentURL.value)
    const timer = () => {
      function cb() {
        if (time.value <= 0) {
          console.log("停止");
          clearInterval(timeId.value);
          showFeedback.value = false;
        } else {
          time.value--;
        }
      }
      timeId.value = setInterval(cb, 1000);
    };

    const copyURL = () => {
      var tt = document.createElement("input");
      tt.value = window.location.href;
      document.body.appendChild(tt);
      tt.select();
      document.execCommand("copy");
      tt.remove();

      showFeedback.value = true;
      timer();

    };
    return { isOpen, copyURL, showFeedback, currentURL, props };
  },
};
</script>

<style lang="scss">
.social-sharing__wrap {
  position: fixed;
  top: 148px;
  right: 0;
  z-index: 998;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 54px;
    top: 65px;
  }
}
.social-sharing__toggler {
  //margin-right: 10px;
  .icon-sharing-menu {
    display: block;
    width: 40px;
    height: 40px;
  }
}

.social-sharing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  width: 48px;
  height: 180px;
  padding: 2rem 0.8rem;
  border-radius: 10px 0 0 10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  .icon {
    margin: 0.8rem 0;
    cursor: pointer;
    @media (max-width: 768px) {
      margin: 0.6rem 0;
    }
  }
  @media (max-width: 768px) {
    border-radius: 10px;
    padding: 25px 9px;
    margin-top: 3px;
    background: rgba(255, 255, 255, 9);
  }
}

.feedbackCopyURL {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  background: $secondary-dark;
  z-index: 9999;
  display: flex;
  border-radius: 50px;
  height: 40px;
  justify-content: center;
  align-items: center;
  @media (max-width:768px) {
    top: 45px;
  }
}
</style>