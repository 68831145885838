<template>
  <div class="banner__block banner-custom">
    <div class="bannerbox">
      <div class="bannerbox__inner">
        <slot name="image"></slot>
        <div class="banner__overlay">
          <div class="banner__content container">
            <slot name="intro"></slot>
          </div>
        </div>
      </div>
    </div>
    <slot name="mask"></slot>
  </div>
</template>

<style lang="scss" scoped>
.bannerbox .bannerbox__inner {
  padding-top: 500px;
}
</style>
