<template>
  <Splide :options="options">
    <SplideSlide v-for="item in cardList" :key="item.ID">
      <router-link :to="'/' + this.$route.params.lang + `/${props.currentRoute}/${item.SeoUrl}`" class="hover-effect-more mb-5">
        <div class="card custom-border">
          <div class="imgbox">
            <div class="imgbox__inner imgbox--ratio-10x7">
              <div v-if="item.MImg" 
                class="imgbox__image"
                :style="{ backgroundImage: 'url(' + item.MImg + ')' }"
              ></div>
              <div v-else 
                class="imgbox__image"
                :style="{ backgroundImage: 'url(' + item.Img + ')' }"
              ></div>
            </div>
          </div>
          <div class="card-img-overlay">
            <h5 class="card-title single-ellipsis">{{ $filters.removeHtmlTag(item.Title) }}</h5>
            <p class="card-text multiline-ellipsis">{{ item.SubTitle }}</p>
          </div>
          <div class="card-info">
            <span class="card-type" v-if="item.NewsClass !== undefined"><span class="deco-border"></span>{{
              item.NewsClass
            }}</span>
            <time class="card-date font-enUS">{{ $filters.dateFormat(item.Date) }}</time>
          </div>
        </div>
      </router-link>
    </SplideSlide>
  </Splide>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Splide,
    SplideSlide,
  },
  props: ["cardList","currentRoute"],
  data() {
    return {};
  },
  setup(props) {
    const options = {
      perPage: 2,
      perMove: 1,
      gap: "1.4rem",
      padding: { left: '0', right: '48rem' },
      rewind: true,
      arrows: false,
      pagination: false,
      width: '100%',
      fixedWidth: '350px',
      drag: "free",
      breakpoints: {
        1921: {
          perPage: 2,
          padding: { left: '0',right: '28rem' },
        },
        1800: {
          perPage: 2,
          padding: { left: '0',right: '24rem' },
        },
        1600: {
          perPage: 2,
          padding: { left: '0',right: '18rem' },
        },
        1450: {
          perPage: 2,
          padding: { left: '0',right: '13rem' },
        },
        1366: {
          perPage: 2,
          padding: { left: '0',right: '9rem' },
        },
        1280: {
          perPage: 2,
          padding: { left: '0',right: '6rem' },
        },
        1200: {
          perPage: 2,
          padding: { left: '0',right: '4rem' },
        },
        1080: {
          perPage: 2,
          padding: { left: '0',right: '6rem' },
        },
        1024: {
          perPage: 2,
          padding: { right: '4rem' },
        },
        800: {
          perPage: 1,
          perMove: 1,
          padding: { right: '4rem' },
        },
        576: {
          perPage: 1,
          gap: "1rem",
        },
        480: {
          perPage: 1,
          fixedWidth: '350px',
        },
        420: {
          perPage: 1,
          fixedWidth: '320px',
        },
        375: {
          perPage: 1,
          gap: "1rem",
          fixedWidth: '300px',
        },
        360: {
          perPage: 1,
          gap: ".8rem",
          fixedWidth: '300px',
        },
        320: {
          perPage: 1,
          gap: ".6rem",
          fixedWidth: '260px',
        },
      },
    };

    return { options, props };
  },
});
</script>

<style lang="scss">
.related__list {
  width: calc(1050px + (100vw - 1050px)/2);
  margin-left: calc((100vw - 1050px)/2);
  @media (max-width:1366px) {
    width: calc(1090px + (100vw - 1090px)/2);
    margin-left: calc((100vw - 1090px)/2);
  }
  @media (max-width:1200px) {
    width: calc(1090px + (100vw - 1090px)/2);
    margin-left: calc((100vw - 1090px)/2);
  }
  @media (max-width:1080px) {
    width: calc(910px + (100vw - 910px)/2);
    margin-left: calc((100vw - 910px)/2);
  }
  @media (max-width:800px) {
    width: calc(670px + (100vw - 670px)/2);
    margin-left: calc((100vw - 670px)/2);
  }
  @media (max-width:600px) {
    width: calc(490px + (100vw - 490px)/2);
    margin-left: calc((100vw - 490px)/2);
  }
  @media (max-width:480px) {
    width: calc(410px + (100vw - 410px)/2);
    margin-left: calc((100vw - 410px)/2);
  }
  @media (max-width:420px) {
    width: calc(360px + (100vw - 360px)/2);
    margin-left: calc((100vw - 360px)/2);
  }
  @media (max-width:400px) {
    width: calc(350px + (100vw - 350px)/2);
    margin-left: calc((100vw - 350px)/2);
  }
  @media (max-width:375px) {
    width: calc(320px + (100vw - 320px)/2);
    margin-left: calc((100vw - 320px)/2);
  }
  @media (max-width:360px) {
    width: calc(310px + (100vw - 310px)/2);
    margin-left: calc((100vw - 310px)/2);
  }
  @media (max-width:330px) {
    width: calc(300px + (100vw - 300px)/2);
    margin-left: calc((100vw - 300px)/2);
  }
  @media (max-width:320px) {
    width: calc(270px + (100vw - 270px)/2);
    margin-left: calc((100vw - 270px)/2);
  }
}

//
.related__list .card-img-overlay {
  top: 22rem!important;
  @media (max-width:576px) {
    top: 21rem!important;
  }
  @media (max-width:420px) {
    top: 19rem!important;
  }
  @media (max-width:375px) {
    top: 17.4rem!important;
  }
  @media (max-width:320px) {
    top: 14rem!important;
  }
}

.related__list a.hover-effect-more:hover .card-img-overlay {
  top: 17rem!important;
  @media (max-width:576px) {
    top: 21rem!important;
  }
  @media (max-width:420px) {
    top: 19rem!important;
  }
  @media (max-width:375px) {
    top: 17.4rem!important;
  }
  @media (max-width:320px) {
    top: 14rem!important;
  }
}

@media (max-width:1199px) {
  a.hover-effect-more:hover .card-text.multiline-ellipsis {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  a.hover-effect-more:hover .card-text.single-ellipsis {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

a.hover-effect-more:hover .card-text.multiline-ellipsis {
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
a.hover-effect-more:hover .card-text.single-ellipsis {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width:576px) {
  a.hover-effect-more:hover .card-text.multiline-ellipsis {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
